<template>
  <v-app>
    <router-view></router-view>
    <v-btn icon class="whatsapp-button" v-if="storeData.whatsappLink" :href="storeData.whatsappLink" target="_blank" aria-label="Chat on WhatsApp">
      <v-icon size="48px" color="white" role="img" aria-hidden="true">mdi-whatsapp</v-icon>
    </v-btn>
  </v-app>
</template>

<script>

!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');



import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})
export default {
  name: 'App',
  data() {
    return {
      storeData: {
      }
    }
  },
  async mounted(){
    console.log(process.env.VUE_APP_ROOT_API),
    this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
    fbq('init', this.storeData.pixelFbId);
    fbq('track', 'PageView');
    this.insertVisit()
  },
  watch: {
    '$route' (to, from) {
      this.insertVisit()
    }
  },
  methods:{
    async insertVisit() {
      try {
        var ipdata = {}
        if(localStorage.getItem('ipdata')){
          ipdata = JSON.parse(localStorage.getItem('ipdata'))
        }else{
          let response = await axios.get("https://ipgeolocation.abstractapi.com/v1/?api_key=4afbf62ff3024a9f9e8e3e22ef334955")
          ipdata = response.data
          localStorage.setItem('ipdata', JSON.stringify(ipdata))
        }
        let ua = `${ipdata.city}/${ipdata.region} - ${navigator.userAgent}`
        let data = {
        "id_store": this.storeData.id,
        "ip_address": ipdata.ip_address,
        "user_agent": ua,
        "page": this.$route.path
        }
        console.log(data)
        await api.post(`website_visits`, data)
      } catch (error) {
        console.error(error)
        this.$toast.error('Falha ao registrar visita')
      }
    },
  }
  
};
</script>

<style scoped>

.whatsapp-button {
    position: fixed;
    bottom: 16px;
    right: 16px;
    background-color: #25D366;
    color: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
    width: 70px;
    height: 70px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
<template>
    <div>
        <v-row class="news" v-if="!isMobile">
            <v-col cols="12" class="news-title">
                <h2>{{ title }}</h2>
            </v-col>
            <v-col cols="3" class="product" v-for="p in products" :key="p.id">
                <img :src="p.src" alt="" @click="$router.replace($route.params.storeSlug == undefined ? `/product/${p.slug}` : `/${$route.params.storeSlug}/product/${p.slug}`)">
                <div class="image-list">
                    <img v-for="img in p.showCaseProducts" :key="img.id" :src="img.image" @click="p.src = img.image" alt="">
                </div>
                <h3 class="title">{{ p.name }}</h3>
                <p class="rate">
                </p>
                <p class="price">{{ parseFloat(p.sellPrice).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</p>
            </v-col>
        </v-row>
        <v-row class="news-mobile" v-if="isMobile">
            <v-col cols="12" class="news-title">
                <h2>{{ title }}</h2>
            </v-col>
            <v-col cols="6" class="product" v-for="p in products" :key="p.id">
                <img :src="p.src" alt="" @click="$router.push($route.params.storeSlug == undefined ? `/product/${p.slug}` : `/${$route.params.storeSlug}/product/${p.slug}`)">
                <div class="image-list">
                    <img v-for="img in p.showCaseProducts" :key="img.id" :src="img.image" @click="p.src = img.image" alt="">
                </div>
                <h3 class="title">{{ p.name }}</h3>
                <p class="rate">
                </p>
                <p class="price">{{ parseFloat(p.sellPrice).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</p>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    name: 'FourProducts',
    props: {
        isMobile: Boolean,
        products: Array,
        title: String
    }
}
</script>

<style scoped>
    /* NEWS */
    .body .news .news-title{
        text-align: center;
        text-transform: uppercase;
        font-family: Exo;
        font-size: 48px;
        font-weight: 700;
        line-height: 63.79px;
        color: #000;
        margin-top: 100px;
    }

    .body .news .product{
        cursor: pointer;
        padding-top: 50px;
    }

    .body .news .product img{
        width: 100%;
        border-radius: 10px;
    }

    .body .news .product .title{
        font-family: Exo;
        font-size: 20px;
        font-weight: 700;
        line-height: 26.58px;
        text-align: left;
    }

    .body .news .product .rate{
        margin-top: 10px;
        font-family: Exo;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.26px;
        text-align: left;
        color: #00000066;
    }

    .body .news .product .price{
        font-family: Exo;
        font-size: 24px;
        font-weight: 700;
        line-height: 31.9px;
        text-align: left;
        margin-top: -10px;
    }

    .body .news .product .image-list{
        margin: 0 auto;
        width: 420px;
        overflow: auto;
        white-space: nowrap;
    }

    .body .news .product .image-list img{
        width: 50px !important;
        height: 50px !important;
        border-radius: 5px;
        margin-right: 5px;
    }


    /* NEWS MOBILE */
    .body .news-mobile .news-title{
        text-align: center;
        text-transform: uppercase;
        font-family: Exo;
        font-size: 28px;
        font-weight: 700;
        line-height: 63.79px;
        color: #000;
        margin-top: 100px;
    }

    .body .news-mobile .product{
        cursor: pointer;
        padding-top: 10px;
    }

    .body .news-mobile .product img{
        width: 100%;
        border-radius: 10px;
    }

    .body .news-mobile .product .title{
        font-family: Exo;
        font-size: 20px;
        font-weight: 700;
        line-height: 26.58px;
        text-align: left;
    }

    .body .news-mobile .product .rate{
        margin-top: 10px;
        font-family: Exo;
        font-size: 16px;
        font-weight: 400;
        line-height: 21.26px;
        text-align: left;
        color: #00000066;
    }

    .body .news-mobile .product .price{
        font-family: Exo;
        font-size: 24px;
        font-weight: 700;
        line-height: 31.9px;
        text-align: left;
        margin-top: -10px;
    }

    .body .news-mobile .product .image-list{
        margin: 0 auto;
        width: 100%;
        overflow: auto;
        white-space: nowrap;
    }

    .body .news-mobile .product .image-list img{
        width: 50px !important;
        height: 50px !important;
        border-radius: 5px;
        margin-right: 5px;
    }
</style>
<template>
    <v-app>
        <!-- <Notice :isMobile="$store.getters.isMobile" :message="message"/> -->

        <Navbar :isMobile="$store.getters.isMobile" :productsInCart="productsInCart"/>

        <v-container>
            <v-row>
                <v-col cols="12" md="6" v-if="$store.getters.isMobile">
                    <img :src="product.src" class="primary-image-mobile" alt="">
                    <div class="image-list-mobile">
                        <img v-for="img in product.showCaseProducts" :key="img.id" :src="img.image" @click="product.src = img.image" alt="">
                    </div>
                </v-col>
                <v-col cols="12" md="6" v-if="!$store.getters.isMobile">
                    <img :src="product.src" class="primary-image" alt="">
                    <div class="image-list">
                        <img v-for="img in product.showCaseProducts" :key="img.id" :src="img.image" @click="product.src = img.image" alt="">
                    </div>
                </v-col>
                <v-col>
                    <v-row>
                        <v-col>
                            <h1>{{ product.name }}</h1>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h2>{{ parseFloat(product.sellPrice).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'}) }}</h2>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <h3>Descrição</h3>
                            <p>{{ product.description }}</p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <h3>Cores</h3>
                            <h4>Feminino</h4>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        v-for="m in colorsFem"
                                        class="mr-2"
                                        :key="m.id"
                                        :color="m.hex"
                                        fab
                                        x-small
                                        @click="selectColor(m, 2)"
                                    >
                                        <v-icon color="green" v-if="variantSelected.idGender == 2 && variantSelected.idColor == m.id">
                                            mdi-check
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                            <br>
                            <br>
                            <h4>Masculino</h4>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        v-for="m in colorsMas"
                                        class="mr-2"
                                        :key="m.id"
                                        :color="m.hex"
                                        fab
                                        x-small
                                        @click="selectColor(m, 1)"
                                    >
                                        <v-icon color="green" v-if="variantSelected.idGender == 1 && variantSelected.idColor == m.id">
                                            mdi-check
                                        </v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col>
                            <h3>Tamanhos</h3>
                            <v-row>
                                <v-col>
                                    <v-btn
                                        v-for="s in sizes"
                                        class="mr-2"
                                        :key="s"
                                        fab
                                        x-small
                                        @click="sizeSelected = s"
                                    >
                                        <v-icon v-if="sizeSelected == s">
                                            mdi-check
                                        </v-icon>
                                        {{ s }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                    
                    <v-row>
                        <v-col cols="12" md="4">
                            <v-container>
                                <v-row class="input-qnt">
                                    <v-col class="remove" @click="qnt <= 1 ? qnt = 1 : qnt--" style="padding: 4px;">
                                        <img src="../assets/icons/minus.svg" alt="">
                                    </v-col>
                                    <v-col class="qnt" style="padding: 4px;">
                                        {{ qnt }}
                                    </v-col>
                                    <v-col class="add" @click="qnt++" style="padding: 4px;">
                                        <img src="../assets/icons/plus.svg" alt="">
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-col>
                        <v-col cols="12" md="6">
                            <button class="btn-default" @click="addToCart" v-if="!hasInCart">Adicionar ao carrinho</button>
                            <button class="btn-default" @click="$router.push($route.params.storeSlug == undefined ? `/cart` : `/${$route.params.storeSlug}/cart`)" v-if="hasInCart">Ver carrinho</button>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>

        <!-- <CostumersRate title="Avaliações" :rates="rates" :isMobile="$store.getters.isMobile"/> -->


        <v-container class="body">
            <FourProducts title="Você pode gostar" :isMobile="$store.getters.isMobile" :products="products.slice(-4)" />
        </v-container>
    
        <Footer :isMobile="$store.getters.isMobile" />
        <Loading v-if="loading"/>
    </v-app>
</template>

<script>
import Notice from '@/components/Notice.vue'
import Navbar from '@/components/Navbar.vue'
import FourProducts from '@/components/FourProducts.vue'
import SlideProduct from '@/components/SlideProduct.vue'
import Footer from '@/components/Footer.vue'
import Loading from '@/components/Loading.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import CostumersRate from '@/components/CostumersRate.vue'

import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})

export default {
    name: 'DetailsPage',
    metaInfo() {
        return {
        title: `${this.storeData.name} - Detalhes`
        }
    },
    components: {
        Notice,
        Navbar,
        FourProducts,
        Footer,
        SlideProduct,
        FontAwesomeIcon,
        CostumersRate,
        Loading
    },
    data(){
        return {
            loading: true,
            storeData: '',
            message: "Cadastre-se e ganhe 20% de desconto no seu primeiro pedido. Inscreva-se agora",
            title: 'Site Loja',
            categories: [],
            productsInCart: 0,
            hasInCart: false,
            topBanner: require(`@/assets/banner-topo.jpg`),
            variantSelected: {},
            sizeSelected: '',
            colorsFem: [],
            colorsMas: [],
            sizes: [],
            color: 0,
            qnt: 1,
            product: {
            },
            products: [
            ],
            rates: [
                {
                    id: 1,
                    rate: 5,
                    name: 'Cliente 1',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                },
                {
                    id: 2,
                    rate: 4,
                    name: 'Cliente 2',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                },
                {
                    id: 3,
                    rate: 3,
                    name: 'Cliente 3',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                },
                {
                    id: 4,
                    rate: 2,
                    name: 'Cliente 4',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                },
                {
                    id: 5,
                    rate: 1,
                    name: 'Cliente 5',
                    text: "I'm blown away by the quality and style of the clothes I received from Shop.co. From casual wear to elegant dresses, every piece I've bought has exceeded my expectations."
                }
            ]
        }
    },
    async created(){
        await this.init()
    },
    async mounted(){
        
    },
    watch: {
        async '$route' (to, from) {
            await this.init()
            await this.scrollToTop();
        }
    },
    methods: {
        async scrollToTop() {
            window.scrollTo(0,0);
        },
        async init(){
            window.fbq('track', 'ViewContent', {
                content_ids: [this.$route.params.id],
                content_type: 'product',
                value: this.product.sale_price,
                currency: 'BRL'
            });
            this.loading = true;
            this.storeData = await this.$store.dispatch('getStore', this.$route.params.storeSlug == undefined ? localStorage.urlStore : this.$route.params.storeSlug)
            await this.getProduct()
            await this.getProducts()
            await this.scrollToTop();
            // await this.verifyCart();
            await this.verifyQntCart();
            this.loading = false;
        },
        async verifyQntCart(){
            this.productsInCart = localStorage.getItem('cart') ? JSON.parse(localStorage.getItem('cart')).length : 0
        },
        async verifyCart(){
            let cart = JSON.parse(localStorage.getItem('cart'))
            if(cart){
                let p = cart.find(p => p.id == this.product.id)
                if(p != undefined){
                    this.hasInCart = true
                }else{
                    this.hasInCart = false
                }
            }else{
                this.hasInCart = false
            }
        },
        async addToCart(){
            window.fbq('track', 'AddToCart', {
                content_ids: [this.product.id],
                content_type: 'product',
                value: this.product.sale_price,
                currency: 'BRL'
            });
            let cart = JSON.parse(localStorage.getItem('cart'))
            // if(this.qnt > this.variantSelected.stock){
            //     alert('Quantidade maior que o estoque')
            //     return
            // }
            if(this.sizeSelected == ''){
                alert('Selecione um tamanho')
                return
            }
            this.product.qnt = this.qnt
            this.product.variantSelected = this.variantSelected
            this.product.sizeSelected = this.sizeSelected
            if(cart){
                cart.push(this.product)
                localStorage.setItem('cart', JSON.stringify(cart))
            }else{
                localStorage.setItem('cart', JSON.stringify([this.product]))
            }
            await this.verifyCart();
            await this.verifyQntCart();
        },
        async getProduct(){
            try {
                let response = await api.get(`products/${this.$route.params.id}`)
                this.product = response.data 

                let productsFem = this.product.showCaseProducts.filter(x => x.idGender == 2)
                let productsMas = this.product.showCaseProducts.filter(x => x.idGender == 1)
                this.colorsFem = productsFem.map(x => x.color)
                this.colorsMas = productsMas.map(x => x.color)

                this.colorsFem = this.colorsFem.filter((v,i) => this.colorsFem.findIndex(x => x.id == v.id) == i)
                this.colorsMas = this.colorsMas.filter((v,i) => this.colorsMas.findIndex(x => x.id == v.id) == i)

                this.variantSelected = this.product.showCaseProducts[0]
                this.sizes = JSON.parse(this.variantSelected.mockup.sizes)
            } catch (error) {
                console.log(error)
            }
        },
        async getProducts(){
            try {
                let response = await api.get(`products/store/${this.storeData.id}`)
                this.products = response.data 
            } catch (error) {
                console.log(error)
            }
        },
        async selectColor(color, gender){
            if(gender == 2){
                this.variantSelected = (this.product.showCaseProducts.filter(x => x.idGender == 2 && x.idColor == color.id))[0]
                this.product.src = this.variantSelected.image
                this.sizes = JSON.parse(this.variantSelected.mockup.sizes)
            }

            if(gender == 1){
                this.variantSelected = (this.product.showCaseProducts.filter(x => x.idGender == 1 && x.idColor == color.id))[0]
                this.product.src = this.variantSelected.image
                this.sizes = JSON.parse(this.variantSelected.mockup.sizes)
            }
        }
    }
}
</script>

<style scoped>
    *{
        font-family: "Exo", sans-serif;
    }
    ::placeholder {
        color: #00000066;
        opacity: 1; /* Firefox */
    }

    h2{
        font-size: 2.5rem;
        font-weight: 700;
    }

    .btn-default{
        width: 100%;
        background-color: #000;
        color: #fff;
        border-radius: 62px;
        padding: 10px;
    }


    .input-qnt{
        border: 1px solid #F0F0F0;
        background-color:#F0F0F0;
        border-radius: 50px;
    }
    .input-qnt .remove img, .input-qnt .add img{
        width: 20px;
        cursor: pointer;
    }

    .input-qnt .qnt{
        text-align: center;
        font-size: 1.5rem;
    }

    .input-qnt .add img{
        float: right;
        margin-top: 8px;
        margin-right: 10px;
    }
    .input-qnt .remove img{
        float: left;
        margin-top: 8px;
        margin-left: 10px;
    }
    /*  */
    /*  */
    /*  */

    .input-default{
        width: 100%;
        padding: 10px;
        padding-left: 15px;
        border: 1px solid #F0F0F0;
        background-color:#F0F0F0;
        border-radius: 50px;
    }

    .top-banner{
        padding: 0;
    }
    .top-banner img{
        width: 100%;
    }
    .top .top-separator{
        margin-bottom: 25vh;
    }

    .top .top-separator img.banner1{
        position: absolute;
        margin-top: -20vh;
        width: 100%;

    }
    .top .top-separator img.banner2{
        position: absolute;
        margin-top: -50vh;
        width: 100%;
    }

    @media (min-width: 1024px) and (max-width: 1440px) {
        .top .top-separator img.banner1{
            position: absolute;
            margin-top: -15vh;
            width: 100%;
        }
        .top .top-separator img.banner2{
            position: absolute;
            margin-top: -43vh;
            width: 100%;
        }
    }


    /* TOP SEPARATOR MOBILE */
    .top .top-separator-mobile{
        margin-bottom: 10vh;
    }
    .top .top-separator-mobile img.banner1{
        position: absolute;
        top: 9rem;
        width: 100%;

    }
    .top .top-separator-mobile img.banner2{
        position: absolute;
        top: 21rem;
        width: 100%;
    }


    .primary-image{
        width: 500px;
        height: 520px;
        display: block;
        margin: 0 auto;
        border-radius: 10px;
        margin-bottom: 20px
    }
    .image-list{
        margin: 0 auto;
        width: 500px;
        overflow: auto;
        white-space: nowrap;
    }

    .image-list img{
        width: 50px !important;
        height: 50px !important;
        border-radius: 5px;
        margin-right: 5px;
    }


    /* mobile */
    .primary-image-mobile{
        width: 100%;
        display: block;
        margin: 0 auto;
        border-radius: 10px;
        margin-bottom: 20px
    }
    .image-list-mobile{
        margin: 0 auto;
        width: 100%;
        overflow: auto;
        white-space: nowrap;
    }

    .image-list-mobile img{
        width: 50px !important;
        height: 50px !important;
        border-radius: 5px;
        margin-right: 5px;
    }

</style>